<template>
  <div class="usage fade-in-top">
    <div class="text-info">
      <h2>{{ $t("usage.p1.title") }}</h2>
      <p>{{ $t("usage.p1.firstParagraph1") }} <b>{{ $t("usage.p1.firstParagraph2") }}</b> {{ $t("usage.p1.firstParagraph3") }}</p>
      <p>{{ $t("usage.p1.secondParagraph") }}</p>
      <p>{{ $t("usage.p1.thirdParagraph") }}</p>

      <br>

      <h2>{{ $t("usage.titleP3") }}</h2>
      <h3>{{ $t("usage.p3.title") }}</h3>
      <p>{{ $t("usage.p3.firstParagraph") }}</p>

      <br>

      <h3>{{ $t("usage.p4.title") }}</h3>
      <p>{{ $t("usage.p4.firstParagraph") }}</p>
      
      <br>

      <section>
        <p>{{ $t("usage.p4.list.title") }}</p>
        <ul>
          <li>{{ $t("usage.p4.list.list1") }}</li>
          <li>{{ $t("usage.p4.list.list2") }}</li>
          <li>{{ $t("usage.p4.list.list3") }}</li>
          <li>{{ $t("usage.p4.list.list4") }}</li>
          <li>{{ $t("usage.p4.list.list5") }}</li>
          <li>{{ $t("usage.p4.list.list6") }}</li>
          <li>{{ $t("usage.p4.list.list7") }}</li>
          <li>{{ $t("usage.p4.list.list8") }}</li>
          <li>{{ $t("usage.p4.list.list9") }}</li>
          <li>{{ $t("usage.p4.list.list10") }}</li>
          <li>{{ $t("usage.p4.list.list11") }}</li>
          <li>{{ $t("usage.p4.list.list12") }}</li>
          <li>{{ $t("usage.p4.list.list13") }}</li>
          <li>{{ $t("usage.p4.list.list14") }}</li>
          <li>{{ $t("usage.p4.list.list15") }}</li>
        </ul>
      </section>

      <br>

      <p><b>{{ $t("usage.p5.firstParagraph1") }}</b>{{ $t("usage.p5.firstParagraph2") }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .usage {
    margin-top: 100px;
    p {
      margin-bottom: 0;
    }
    ul {
      margin-top: 0;
    }
  }
</style>